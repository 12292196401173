/*===========================
old-button
================================*/
.iq-button, [type=submit] {
    z-index: 1;
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-regular);
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    padding: 15px 45px;
    background: var(--primary-color);
    color: var(--white-color);
    transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

a.iq-button-animated.linked-btn:visited,
a.iq-button.iq-btn-link:visited {
    color: var(--primary-color);
}

a.iq-button:visited,
a.iq-button-animated:visited {
    color: var(--white-color);
}

/*button with Outline*/
.iq-btn-outline {
    color: var(--primary-color);
    background: transparent;
    border: 2px solid var(--primary-color);
}

.iq-button.iq-btn-link, .iq-btn-link {
    font-weight: var(--font-weight-medium);
    color: var(--primary-color);
    background: transparent;
    padding: 0;
}


/*button Hover Effect */
.iq-button:hover, .iq-button:focus {
    color: var(--white-color);
    background: var(--secondary-color);
}

.iq-btn-outline:hover, .iq-btn-outline:focus {
    border-color: var(--secondary-color);
    color: var(--white-color);
}

.iq-btn-link:hover, .iq-btn-link:focus {
    color: var(--secondary-color);
    background: transparent;
}


.iq-button.iq-btn-link:hover,
.iq-btn-link:hover {
    color: var(--secondary-color);
    text-decoration: none;
}

.iq-button.iq-btn-outline:hover {
    background: var(--primary-color);
    color: var(--white-color);
}

/*=====================
new-button
==========================*/
.iq-button-animated, .rs-button-animated {
    display: inline-flex;
    align-items: center;
    border: none;
    background: var(--primary-color);
    font-size: var(--font-size-normal);
    border-radius: var(--border-radius-box);
    -webkit-border-radius: var(--border-radius-box);
    padding: 12px 24px;
    color: var(--white-color);
    letter-spacing: var(--letter-spacing-one);
    font-weight: var(--font-weight-regular);
    text-transform: capitalize;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.iq-button-animated .btn_text, .rs-button-animated .btn_text {
    color: var(--white-color);
}

.iq-button-animated:hover,
.iq-button-animated:hover .btn_text .rs-button-animated:hover,
.rs-button-animated:hover .btn_text {
    color: var(--white-color);
}

.iq-button-animated .btn_text-main, .rs-button-animated .btn_text-main {
    display: inline-block;
    position: relative;
    overflow: hidden;
    line-height: var(--font-line-height-h5);
}

.iq-button-animated .btn_text-main .btn_text.one, .rs-button-animated .btn_text-main .btn_text.one {
    position: relative;
    display: inline-block;
    transform: translateY(0);
    transition: transform .3s ease-out .15s;
}

.iq-button-animated:hover .btn_text-main .btn_text.one, .rs-button-animated:hover .btn_text-main .btn_text.one {
    transform: translateY(100%);
    transition: transform .3s ease-out;
}

.iq-button-animated .btn_text-main .btn_text.two, .rs-button-animated .btn_text-main .btn_text.two {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    transition: transform .3s ease-out;
}


.iq-button-animated:hover .btn_text-main .btn_text.two, .rs-button-animated:hover .btn_text-main .btn_text.two {
    transform: translateY(0);
    transition: transform .3s ease-out .15s;
}

.iq-button-animated .btn-icon, .rs-button-animated .btn-icon {
    margin-left: 8px;
}

.iq-button-animated .btn-icon svg line, .rs-button-animated .btn-icon svg line {
    stroke-dashoffset: 0;
    stroke-dasharray: 10;
    transition: stroke-dashoffset .6s ease-out;
}

.iq-button-animated:hover .btn-icon svg line, .rs-button-animated:hover .btn-icon svg line {
    stroke-dashoffset: 20;
}

/*=====================
new-link-button
==========================*/
.iq-button-animated.linked-btn, .rs-button-animated.linked-btn {
    position: relative;
    padding: 0;
    background: transparent;
    color: var(--primary-color);
}

.iq-button-animated.linked-btn:hover, .rs-button-animated.linked-btn:hover {
    background: transparent;
}

.iq-button-animated.linked-btn .btn_text, .rs-button-animated.linked-btn .btn_text {
    color: var(--primary-color);
}

.iq-button-animated.linked-btn:hover .btn_text, .rs-button-animated.linked-btn:hover .btn_text {
    color: var(--primary-color);
}

.iq-button-animated.linked-btn .btn-icon svg line, .rs-button-animated.linked-btn .btn-icon svg line {
    stroke: var(--primary-color);
}